.footer {
    display: flex;
    width: 100%;
    background-color: #4f6070;
    padding: 50px 0;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
}

.footer_container {
    width: 1100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.footer_box {
    width: calc(100%/4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer_box .title {
    font-size: 1rem;
    color: #929aa3;
    margin-bottom: 20px;
}

.footer_box .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #d5dadf;
    font-size: 14px;
}

.footer_box .socials {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.footer_box .socials svg {
    width: 20px;
    height: 20px;
    color: #fff;
}

.footer_box h4 {
    font-size: 14px;
    color: #d5dadf;
}

.footer_box .submit {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.footer_box .submit input {
    height: 40px;
    width: 86%;
    font-size: 1rem;
    border-radius: 0px;
    background-color: #656d75;
}

.footer_box .submit input:focus {
    outline: none;
}

.footer_box .submit button {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 5px;
    margin-left: .5rem;
    border: none;
    background-color: #5bb7ed;
    color: white;
}

@media (max-width:792px) {
    .footer_container {
        flex-direction: column;
        gap: 20px;
    }

    .footer_box {
        width: 50%;
        justify-content: baseline;
        align-items: center;
    }
}