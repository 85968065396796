header {
    width: 100%;
    position: relative;
}

.topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(189, 184, 184);
    font-family: 'Poppins', sans-serif;
    height: 80px;
    font-size: .85rem;
}

.topbar-logo {
    margin-left: 11rem;
}

.topbar img {
    width: 190px;
    height: auto;
}

.topbar-right {
    width: 40%;
    display: flex;
    align-items: center;
    height: 100%;
}

.topbar-right .call, .topbar-right .open-timing {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.call-icon, .time-icon {
    height: 100%;
    color: #5bb7ed;
    font-size: 1.4rem;
    display: flex;
    margin-right: 1rem;
}

.num-line1, .time-line1 {
    color: #282626;
}

.time-line2 {
    color: #827c7c;
}

nav {
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    font-family: 'Poppins', sans-serif;
}

nav ul {
    padding-left: 0;
    margin-bottom: 0;
}

.menu-icon {
    display: none;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    margin-left: 1rem;
    cursor: pointer;
}

.social-icons {
    display: flex;
}

.social-icons span {
    font-size: 17px;
    margin: 0 .6rem;
    display: flex;
}


.facebook:hover {
    color: #3B5998;
}

.twitter:hover {
    color: #55ACEE;
}

.google:hover {
    color: #dd4b39;
}

.instagram:hover {
    color: #bc2a8d;
}

.nav-logo {
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.nav-logo img {
    width: 150px;
    height: auto;
}

.nav-links ul {
    list-style: none;
    display: flex;
    align-items: center;
}

.nav-links a,
.mobile-menu-links a {
    color: #222222;
    text-decoration: none;
}

.nav-links li {
    padding: 1rem 1.5rem;
    font-size: 15px;
}

.nav-links li:hover,
.mobile-menu-links li:hover {
    text-decoration: underline;
}

.nav-right {
    margin-right: 1rem;
}

.nav-right a {
    color: #222222;
}

.nav-right span {
    font-size: 22px;
    margin: 0 .6rem;
}

.nav-right a:hover {
    color: #01bad4;
}

.mobile-menu {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    z-index: 99;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s;
}

.mobile-menu-close {
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all .3s;
    visibility: hidden;
    animation: menufade .3s linear;
}

@keyframes menufade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.close-icon {
    font-size: 32px;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 10%;
    right: 10%;
    cursor: pointer;
}

.column-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mobile-logo img {
    width: 156px;
}

.mobile-menu-links ul {
    list-style: none;
    margin-top: 2rem;
}

.mobile-menu-links li {
    font-family: 'Poppins', sans-serif;
    padding: 1rem 4rem;
    font-size: 1.3rem;
    text-align: center;
}

@media screen and (max-width:1130px) {
    .nav-logo {
        display: flex;
    }

    .topbar {
        display: none;
    }

    .nav-links {
        display: none;
    }

    .social-icons {
        display: none;
    }

    .menu-icon {
        display: flex;
    }

    .nav-right .account {
        font-size: 20px;
    }

    .nav-right .account a{
        display: flex;
    }
}