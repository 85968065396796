.otp_box {
    display: flex;
    flex-direction: column;
    width: 370px;
    background-color: #fff;
    padding: 1.3rem;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
    margin: 200px auto 0 auto;
}

.otp_title {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 1rem;
}

.otp_field {
    padding: 0.7rem;
    text-align: center;
    letter-spacing: 1rem;
    width: 300px;
    justify-self: center;
    font-size: 2.3rem;
    outline: none;
    border: 1px solid black;
    border-radius: 10px;
    align-self: center;
    margin: 1rem 0;
}

.otp_btn {
    padding: 1rem 0;
    font-size: 1.4rem;
    border-radius: 10px;
    background-color: #59a5bc;
    border: none;
    outline: none;
    border-radius: 10px;
}