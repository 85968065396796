.forgotContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.reset_password {
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    padding: 1.2rem;
    border-radius: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.reset_title {
    margin: 0 0 10px 0;
    padding: 0;
    font-size: 2rem;
    text-align: center;
}

.detail {
    font-size: 1rem;
    color: gray;
}

.reset_field {
    padding: 1rem;
    outline: none;
    border: none;
    background-color: #dddddd;
    margin: 1rem 0;
    font-size: 1.3rem;
    border-radius: 5px;
}

.reset_btn {
    padding: 1rem 0;
    font-size: 1.4rem;
    background-color: #273272;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
    margin-top: 1.3rem;
    margin-bottom: 0.8rem;
}

.reset_btn:hover {
    background-color: #4455b2;
}

.sign_in {
    text-align: center;
    font-size: 1.2rem;
}

@media screen and (max-width: 450px) {
    .reset_password {
        width: 100%;
        height: 100%;
    }
}