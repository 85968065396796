.container{
    width: 100%;
    /* margin:20px 0 40px 0; */
    font-family: 'poppins', 'sans-serif';
    margin: 2rem 0;
}
.container h1{
    font-size: 24px;
    margin: 0 0 20px 0;
    color: #444;
}
.doctors{
    display: flex;
    flex-wrap: wrap;
}
.doctor{
    display: flex;
    align-items: center;
    margin: 1rem;
    width: clamp(400px,35vw,700px);
}
.doctor img{
    margin-top: 20px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
}
.doctor div{
    padding: 20px;
    color: #666;
}
.doctor p{
    line-height: 25px;
    font-size: 15px;
    font-weight: 300;
    text-align: left;
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
}
.doctor h3{
    margin: 10px 0;
    font-size: 22px;
    font-weight: bold;
}
.doctor h4{
    margin: 0;
    font-size: 18px;
    font-weight: 400;
}
.doctor span{
    color:#5bb7ed;
}

@media (max-width:800px){
    .doctor{
        width: clamp(400px,60vw,700px);
    }
}