.home-frame {
    width: 100%;
    height: calc(100vh - 61px);
    background: linear-gradient(to right, rgb(255,255,255,.2), rgb(255,255,255,.2)) ,url(../Assets/Slider_1.jpg) no-repeat;
    background-size: cover;
}

.home-frame-content {
    height: 100%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 9.5rem;
}

.home-frame .line1 {
    font-family: 'Libre Baskerville', serif;
    font-size: 1.5rem;
    color: #222;
}

.home-frame .line2 {
    font-family: 'Poppins', sans-serif;
    font-size: 3rem;
    color: #222;
    font-weight: bold;
    margin-bottom: 1rem;
}

.shop-now {
    font-family: 'Poppins', sans-serif;
    font-size: .875rem;
    text-decoration: none;
    color: #222;
    background-color: transparent;
    border: 2px solid #222;
    border-radius: 15px;
    padding: .6rem 3rem;
    font-weight: 600;
    transition: all .15s;
    font-size: 1rem;
}

.shop-now:hover {
    color: white;
    border: 2px solid #01bad4;
    background-color: #01bad4;
    transition: all .15s;
}

.service-section {
    margin: 3rem 0;
}

.service-section .all-services {
    display: flex;
    width: 100%;
    padding: 0 2rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.service-section h1 {
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-weight: 100;
    color: rgb(128, 128, 128);
    margin-bottom: 2rem;
}

@media screen and (max-width:1350px) {

    .home-frame-content {
        padding-left: 7rem;
    }
}

@media screen and (max-width:1250px) {

    .home-frame-content {
        width: 55%;
    }
}

@media screen and (max-width:1130px) {
    .home-frame-content {
        padding-left: 4rem;
    }

}

@media screen and (max-width:1045px) {
    .home-frame-content {
        padding-left: 3rem;
        width: 60%;
    }

}

@media screen and (max-width:910px) {

    .home-frame-content {
        width: 100%;
    }

    .home-frame .line2 {
        margin-bottom: .7rem;
    }

    .home-frame .line3 {
        display: none;
    }
}

@media screen and (max-width:556px) {
    .home-frame .line1 {
        font-size: 1rem;
    }

    .home-frame .line2 {
        font-size: 2.6rem;
        margin-bottom: .7rem;
    }

}

@media screen and (max-width:476px) {
    .home-frame-content {
        padding-left: 1.5rem;
    }

}

@media screen and (max-width:430px) {
    .home-frame .line2 {
        font-size: 2.4rem;
    }
}

@media screen and (max-width:395px) {
    .home-frame .line2 {
        font-size: 2.1rem;
    }
}