@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

html {
    font-size: 16px;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* font-family: 'Libre Baskerville', serif; */
    /* font-family: 'Poppins', sans-serif; */
  }
    :root {
      --blue: #5ab7ed;
      --purple: #4e5fcb;
      --lighttext: #7e7e7e;
      --darktext: #0A0A0A;
      --red: #ed5b5bfa;
      --white: #fff;
    }